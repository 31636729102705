<template>
  <div class="hero text--center" :class="{ narrow, padded }">
    <h1 class="shadowed">{{ title }}</h1>
    <h2 class="shadowed">{{ subtitle }}</h2>
    <div class="hero__bg" v-if="images">
      <nuxt-img v-if="imagesIsSingle" :provider="imageProvider" :src="imageLocation"/>
      <Swiper
        v-else
        :modules="[SwiperAutoplay, SwiperNavigation]"
        :navigation="imagesFormatted !== undefined && imagesFormatted.length > 1"
        :loop="true"
        :speed="1000"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: true
        }"
      >
        <SwiperSlide v-for="image in imagesFormatted" :key="image.id">
          <nuxt-img provider="strapi" :src="getFlattenedStrapiImageLocation(image)"/>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Strapi4ResponseMany } from "@nuxtjs/strapi/dist/runtime/types";
import { PropType } from "nuxt/dist/app/compat/capi";
import Image from "~/typings/items/image";

const props = defineProps({
  title: {
    type: String as PropType<string>,
    default: ''
  },
  subtitle: {
    type: String as PropType<string>,
    default: ''
  },
  images: {
    type: Object as PropType<string|Strapi4ResponseMany<Image>>,
    required: false
  },
  padded: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  narrow: {
    type: Boolean as PropType<boolean>,
    default: false
  }
});

const imagesFormatted = computed(() => !props.images || typeof props.images === 'string' ? props.images : flattenStrapiCollection(props.images));
const imagesIsSingle = computed(() => !props.images || typeof imagesFormatted.value === 'string' || (Array.isArray(imagesFormatted.value) && imagesFormatted.value.length === 1));
const imageLocation = computed(() => Array.isArray(imagesFormatted.value) ? getFlattenedStrapiImageLocation(imagesFormatted.value?.[0]) : imagesFormatted.value);
const imageProvider = computed(() => !props.images || typeof imagesFormatted.value === 'string' ? 'local' : 'strapi');
</script>

<style lang="scss">
.hero {
  width: 100%;
  background: $dark_background;
  color: $background;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10rem 2rem;
  position: relative;
  z-index: 0;

  @include breakpoint(null, $sm) {
    padding: 8rem 2rem 4rem;
  }

  &.padded {
    padding-bottom: 10rem + 10rem;

    @include breakpoint(null, $sm) {
      padding-bottom: 4rem + 4rem;
    }
  }

  &.narrow {
    padding-bottom: 4rem;

    @include breakpoint(null, $sm) {
      padding-bottom: 2rem;
    }

    &.padded {
      padding-bottom: 4rem + 10rem;

      @include breakpoint(null, $sm) {
        padding-bottom: 2rem + 4rem;
      }
    }
  }

  h1 {
    font-size: 4rem;
    font-weight: 800;
    margin-bottom: 1rem;
    z-index: 2;

    @include breakpoint(null, $sm) {
      font-size: 1.8rem;
    }
  }

  h2 {
    font-size: 2rem;
    font-weight: 600;
    z-index: 2;

    @include breakpoint(null, $sm) {
      font-size: 1.2rem;
    }
  }
}

.hero__bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  
  img, picture {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>
